<template>
  <div class="manage-project-marketing-staff">
    <div class="container fluid">
      <div class="p-3">
        <h2 class="mb-1">Manage Developer Users</h2>
        <chip v-if="developer.name" class="mt-1 bg-secondary fg-white">
          {{ developer.name }}
        </chip>
      </div>
      <!-- ========================= Table Listing ========================= -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="projectMarketingStaffTableColumns"
          :rows="projectMarketingStaffTableData"
          :totalRows="projectMarketingStaffTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="openProjectMarketingStaffDetail"
        >
          <div slot="emptystate">
            <no-data
              message="There is no developer user created yet."
            ></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="toAddProjectMarketingStaff">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <!-- Table Buttons -->
            <span v-if="props.column.field == 'actions'" @click.stop>
              <dropdown-button buttonClass="circle flat">
                <template #buttonContent>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <div class="card text-left">
                  <fd-list-item
                    class="cursor-pointer unselectable"
                    @click="toEditProjectMarketingStaff(props.row.id)"
                  >
                    <i class="fas fa-pen"></i>
                    <span class="ml-2">Edit Project Developer User</span>
                  </fd-list-item>
                  <fd-list-item
                    class="cursor-pointer unselectable"
                    @click="
                      openToggleProjectMarketingStaffStatus(
                        props.row.id,
                        props.row.isActive
                      )
                    "
                  >
                    <i class="fas fa-ban"></i>
                    <span class="ml-2">{{
                      props.row.isActive
                        ? "Ban Developer User"
                        : "Unban Developer User"
                    }}</span>
                  </fd-list-item>
                </div>
              </dropdown-button>
            </span>
            <!-- Active status -->
            <span
              v-else-if="props.column.field == 'isActive'"
              class="status d-flex align-items-center justify-content-center mr-1"
              :class="getStatusClass(props.row.isActive)"
            >
              <i
                class="fas"
                :class="{
                  'fa-check': props.row.isActive,
                  'fa-times': !props.row.isActive
                }"
              ></i>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import {
  projectMarketingStaff as projectMStaffAPI,
  developer as developerAPI
} from "@/api";

export default {
  components: {
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem"),
    Chip: () => import("@/components/GlobalComponents/Chip")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      developer: {},

      projectMarketingStaffTableData: [],
      projectMarketingStaffTablePagination: {},
      projectMarketingStaffTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Active",
          field: "isActive",
          tdClass: "text-center",
          width: "100px"
        },
        {
          label: "Role",
          field: "rolePermission.name"
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Email",
          field: "user.email"
        },
        {
          label: "Contact No",
          field: (row) => this.$getUserPhoneNumber(row.user)
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      agencyOptions: [],
      branchOptions: [],
      isFilterActive: false,
      filter: {
        "name[partial]": "",
        email: "",
        contact: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        this.getDeveloper();
        await this.getAllProjectMarketingStaffs();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ page: params.currentPage });
      this.getAllProjectMarketingStaffs().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ perPage: params.currentPerPage });
      this.getAllProjectMarketingStaffs().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddProjectMarketingStaff() {
      this.$router.push({
        name: "ManageDeveloperUsersAdd",
        params: {
          developerId: this.$route.params.developerId
        }
      });
    },
    toEditProjectMarketingStaff(id) {
      this.$router.push({
        name: "ManageDeveloperUsersEdit",
        params: { developerId: this.$route.params.developerId, userId: id }
      });
    },
    openToggleProjectMarketingStaffStatus(id, isActive) {
      // Set message
      let action;
      isActive ? (action = "ban") : (action = "unban");
      let message = `Are you sure you want to ${action} this user?`;

      this.$confirm({
        message: message,
        type: "alert"
      }).then((confirmed) => {
        if (confirmed) {
          this.confirmToggleStatus(id, !isActive);
        }
      });
    },
    async confirmToggleStatus(id, status) {
      this.$store.commit("setIsLoading", true);
      await this.setProjectMarketingStaffStatus(id, status);
      this.getAllProjectMarketingStaffs().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    openProjectMarketingStaffDetail(event) {
      this.currentProjectMarketingStaffDetail = event.row;
      this.isProjectMarketingStaffDetailShown = true;
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.$store.commit("setIsLoading", true);

      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());

      this.getAllProjectMarketingStaffs().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    clearFilter() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllProjectMarketingStaffs().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    // ========================== API Related =========================
    async getDeveloper() {
      let data = await developerAPI.getDeveloper(
        this.$route.params.developerId
      );
      this.developer = data;
    },
    async getAllProjectMarketingStaffs() {
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await projectMStaffAPI.getProjectMarketingStaffs({
          queries: {
            "developer:id": this.$route.params.developerId,
            ...filteredParam
          },
          page: this.tableParams.page,
          limit: this.tableParams.perPage
        });

        this.projectMarketingStaffTableData = this._.cloneDeep(data.data);
        this.projectMarketingStaffTablePagination = this._.cloneDeep(
          data.meta.pagination
        );
      } catch (error) {
        throw error;
      }
    },
    async setProjectMarketingStaffStatus(id, isActive) {
      try {
        let response = await projectMStaffAPI.editActive(id, {
          isActive: isActive
        });
        return response;
      } catch (error) {
        throw error;
      }
    },
    // ======================== Miscellaneous =========================
    getStatusClass(status) {
      switch (status) {
        case true:
          return "active";
        case false:
          return "inactive";

        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-project-marketing-staff {
  .status {
    padding: 10px 25px;
    border-radius: 5px;
    color: white;
    background: $color-success;
    &.active {
      background: $color-success;
    }
    &.inactive {
      background: $color-warn;
    }
  }
}
</style>
