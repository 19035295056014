var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-project-marketing-staff"},[_c('div',{staticClass:"container fluid"},[_c('div',{staticClass:"p-3"},[_c('h2',{staticClass:"mb-1"},[_vm._v("Manage Developer Users")]),(_vm.developer.name)?_c('chip',{staticClass:"mt-1 bg-secondary fg-white"},[_vm._v(" "+_vm._s(_vm.developer.name)+" ")]):_vm._e()],1),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.projectMarketingStaffTableColumns,"rows":_vm.projectMarketingStaffTableData,"totalRows":_vm.projectMarketingStaffTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.openProjectMarketingStaffDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('dropdown-button',{attrs:{"buttonClass":"circle flat"},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-v"})]},proxy:true}],null,true)},[_c('div',{staticClass:"card text-left"},[_c('fd-list-item',{staticClass:"cursor-pointer unselectable",on:{"click":function($event){return _vm.toEditProjectMarketingStaff(props.row.id)}}},[_c('i',{staticClass:"fas fa-pen"}),_c('span',{staticClass:"ml-2"},[_vm._v("Edit Project Developer User")])]),_c('fd-list-item',{staticClass:"cursor-pointer unselectable",on:{"click":function($event){return _vm.openToggleProjectMarketingStaffStatus(
                      props.row.id,
                      props.row.isActive
                    )}}},[_c('i',{staticClass:"fas fa-ban"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(props.row.isActive ? "Ban Developer User" : "Unban Developer User"))])])],1)])],1):(props.column.field == 'isActive')?_c('span',{staticClass:"status d-flex align-items-center justify-content-center mr-1",class:_vm.getStatusClass(props.row.isActive)},[_c('i',{staticClass:"fas",class:{
                'fa-check': props.row.isActive,
                'fa-times': !props.row.isActive
              }})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no developer user created yet."}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn main mr-2",on:{"click":_vm.toAddProjectMarketingStaff}},[_c('i',{staticClass:"fas fa-plus"})]),_c('button',{staticClass:"btn toggle",class:{ active: _vm.isFilterActive },on:{"click":_vm.filterToggle}},[_c('i',{staticClass:"fas fa-filter"})])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }